<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Reporte por Proveedores

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsRSenior"
              :columns="columns"
              :filename="'Reporte por Proveedores'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px"
            >
              Descargar
            </vue-excel-xlsx>
            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSenior()"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :sort-by="['junior']"
            :headers="headers"
            :items="itemsRSenior"
            :search="search"
          >
            <template slot="body.append">
              <tr class="phone-total pink--text">
                <th colspan="1" class="title">Totales</th>
                <th class="title">{{ sumField("cantidad_pro") }}</th>
                <th colspan="1" class="title">
                  {{ sumField("subtotal_pro") }}
                </th>
                <th colspan="1" class="title">
                  {{ sumField("compra_pro") }}
                </th>
                <th colspan="1" class="title">
                  {{ sumField("abono") }}
                </th>
                <th colspan="1" class="title">
                  {{ sumField("resta") }}
                </th>
                <th colspan="2" class="title">
                  {{ sumField("restatwo") }}
                </th>
              </tr>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2 mx-auto"
                @click="loadSeniorPro(item.id_proveedor)"
              >
                mdi-account
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                @click="loadRSeniorSS(item.id_proveedor)"
              >
                mdi-eye
              </v-icon>
              <v-icon
                color="green"
                class="mr-2 mx-auto"
                @click="
                  viewAbono(
                    item.id,
                    item.senior,
                    item.proveedor,
                    item.id_proveedor
                  )
                "
              >
                mdi-account-cash
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogprecio" width="500">
      <v-card>
        <v-card-title>
          Precio
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="precio_compra"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                @click="updateDataPrecio()"
                class="mt-3"
                color="primary"
                rounded
                block
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogprecio = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogsenior" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}

          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPro"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-row>
          <v-col v-if="statusPDFView">
            <iframe
              width="100%"
              height="100%"
              :src="rutaPDF"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col>
            <v-data-table
              :headers="headersPro"
              :items="itemsSeniorPro"
              :search="searchPro"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2 mx-auto"
                  @click="loadRSeniorSSJ(item.id_senior)"
                >
                  mdi-eye
                </v-icon>
              </template>

              <template slot="body.append">
                <tr class="pink--text">
                  <th class="title">Totales</th>
                  <th class="title">{{ sumFieldSPro("subtotal_se") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogsenior = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogjunior" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersSSJ"
          :items="itemsRSeniorSSJ"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="viewOrden(item.id)">
              mdi-eye
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="5" class="title">Totales</th>
              <th class="title">{{ sumFieldSJ("cantidad") }}</th>
              <th class="title">{{ sumFieldSJ("precio") }}</th>
              <th class="title">{{ sumFieldSJ("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogjunior = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="dialogrs" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}

          <v-btn color="primary" @click="mostrarPDF()" class="ml-5">{{
            msg_mostrar
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-row>
          <v-col v-if="statusPDFView">
            <iframe
              width="100%"
              height="100%"
              :src="rutaPDF"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col>
            <v-data-table
              :headers="headersSS"
              :items="itemsRSeniorSS"
              :search="searchSS"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2 mx-auto"
                  @click="viewPDF(item.ruta_co, item.pagina)"
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  color="primary"
                  class="mr-2 mx-auto"
                  @click="loadPrecio(item.id)"
                >
                  mdi-cash
                </v-icon>
              </template>

              <template slot="body.append">
                <tr class="pink--text">
                  <th class="title">Totales</th>
                  <th class="title">{{ sumFieldS("cantidad") }}</th>
                  <th class="title">{{ sumFieldS("precio") }}</th>
                  <th class="title">{{ sumFieldS("subtotal") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogabono" width="1000">
      <v-card>
        <v-card-title>
          Abonos de {{ proveedorName }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchProv"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ab_fecha"
                type="date"
                label="Fecha"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="ab_monto" label="Monto"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="ab_banco" label="Banco"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ab_transaccion"
                label="Nro. Transacción"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <croppa
                v-model="myCroppa"
                :width="400"
                :height="250"
                :placeholder-font-size="16"
                placeholder="Subir foto del articulo"
                :show-loading="true"
                :loading-size="50"
                :zoom-speed="10"
                loading-color="#606060"
              >
              </croppa>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="primary" @click="addAbono()" rounded>Agregar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-data-table
          :headers="headersAbono"
          :items="itemsAbono"
          :search="searchAbono"
        >
          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="1" class="title">Totales</th>
              <th class="title">{{ sumFieldAbono("monto") }}</th>
            </tr>
          </template>
          <template v-slot:item.deposito="{ item }">
            <router-link to=""
              ><img
                width="80"
                @click="verFoto(item.deposito)"
                :src="item.deposito"
                alt=""
            /></router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="deleteAbono(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogabono = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
export default {
  name: "RSenior",
  components: {
    pdf,
  },
  data: () => ({
    myCroppa: {},
    currentPage: 0,
    pageCount: 0,
    numPages: 1,
    search: "",
    searchS: "",
    searchSS: "",
    searchPro: "",
    searchAbono: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    itemsRSeniorSSJ: [],
    itemsSeniorPro: [],
    dialogjunior: "",
    id: "",
    name: "",
    dialogrs: "",
    dialogsenior: false,
    description: "",
    dialogprecio: false,
    status: true,
    statusData: 1,
    statusPDFView: false,
    precio_compra: "",
    rutaPDF: "",
    precio: "",
    itemsCampana: [],
    id_campana: "",
    proveedorName: "",
    id_proveedor: "",

    dialogabono: false,
    itemsAbono: [],
    searchProv: "",

    ab_fecha: "",
    ab_monto: "",
    ab_banco: "",
    ab_transaccion: "",
    ab_image: "",
    ab_id_senior: "",

    msg_mostrar: "Mostrar PDF",
    headers: [
      { text: "Proveedor", value: "proveedor" },

      { text: "Cantidad", value: "cantidad_pro" },
      { text: "Total Venta", value: "subtotal_pro" },
      { text: "Total a Pagar", value: "compra_pro" },
      { text: "Pago Abonado", value: "abono" },
      { text: "Saldo Venta", value: "resta" },
      { text: "Saldo a Pagar", value: "restatwo" },
      { text: "Acción", value: "actions" },
    ],

    headersAbono: [
      { text: "Fecha", value: "fecha_corta" },
      { text: "Monto", value: "monto" },
      { text: "Deposito", value: "deposito" },
      { text: "Banco", value: "banco" },
      { text: "Nro. Transacción", value: "nro_transaccion" },
      { text: "Acción", value: "actions" },
    ],

    columns: [
      { label: "Proveedor", field: "proveedor" },

      { label: "Cantidad", field: "cantidad_pro" },
      { label: "Sub Total", field: "subtotal_pro" },
    ],

    headersS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "SubTotal", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],
    headersPro: [
      {
        text: "Senior",
        align: "start",
        value: "senior",
      },
      { text: "SubTotal", value: "subtotal_se" },
      { text: "Acción", value: "actions" },
    ],

    headersSS: [
      {
        text: "Senior",
        align: "start",
        value: "senior",
      },
      { text: "Junior", value: "junior" },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio Venta", value: "precio" },
      { text: "Precio Compra", value: "precio_compra" },
      { text: "Sub Total", value: "subtotal" },
      { text: "Acciones", value: "actions" },
    ],

    headersSSJ: [
      { text: "Junior", value: "junior" },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
  }),
  mounted() {
    //this.loadRSenior();
    this.loadRSeniorS();
    this.loadCampanas();

    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
  methods: {
    loadRSenior() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "campana/rproveedor/" + this.id_campana
        )
        .then((result) => {
          this.itemsRSenior = result.data;
        });
    },
    viewAbono(id, senior, proveedor, idPro) {
      this.ab_id_senior = id;
      this.dialogabono = true;
      this.senior = senior;
      this.proveedorName = proveedor;
      this.id_proveedor = idPro;
      this.loadAbonos(this.id_proveedor);
    },
    loadAbonos(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "abonos_filter/pro/" + id + "/" + this.id_campana)
        .then((result) => {
          this.itemsAbono = result.data;
        });
    },
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    loadRSeniorSSJ(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/senior/" + id)
        .then((result) => {
          this.dialogjunior = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSSJ = result.data;
        });
    },
    loadRSeniorS() {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior_with")
        .then((result) => {
          this.itemsRSeniorS = result.data;
        });
    },

    loadRSeniorSS(id) {
      this.precio = id;
      axios
        .get(process.env.VUE_APP_API_URL + "rep/proveedor/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].proveedor;
          this.itemsRSeniorSS = result.data;
        });
    },

    loadSeniorPro(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/senior/proveedor/" + id)
        .then((result) => {
          this.dialogsenior = true;

          this.itemsSeniorPro = result.data;
        });
    },

    loadPrecio(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "listproductos/" + id)
        .then((result) => {
          this.dialogprecio = true;
          this.precio_compra = result.data[0].precio_compra;
          this.id_precio = result.data[0].id;
        });
    },
    updateDataPrecio() {
      let data = {
        precio_compra: this.precio_compra,
        status: this.status,
      };
      axios
        .put(
          process.env.VUE_APP_API_URL + "productos/precio/" + this.id_precio,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Precio actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSeniorSS(this.precio);
            this.dialogprecio = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    viewPDF(ruta, pagina) {
      this.statusPDFView = true;

      this.rutaPDF =
        "https://point.qreport.site/files/" +
        ruta +
        "#page=" +
        pagina +
        "&zoom=110&navpanes=0&scrollbar=0";

      this.msg_mostrar = "Ocultar PDF";
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    mostrarPDF() {
      if (this.statusPDFView == true) {
        this.statusPDFView = false;
        this.msg_mostrar = "Mostrar PDF";
      } else {
        this.statusPDFView = true;
        this.msg_mostrar = "Ocultar PDF";
      }
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    deleteAbono(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el abono no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "abonos/pro/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Abono eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadAbonos(this.id_proveedor);
                this.loadRSenior();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El abono no fue eliminado", "error");
        }
      });
    },

    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    addAbono() {
      this.dataUrl = this.myCroppa.generateDataUrl("image/jpeg", 0.8);
      let data = {
        id_campana: this.id_campana,
        id_proveedor: this.id_proveedor,
        fecha: this.ab_fecha,
        monto: this.ab_monto,
        banco: this.ab_banco,
        nro_transaccion: this.ab_transaccion,
        deposito: this.dataUrl,
        status: 1,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "abonosPro", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Abono registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
            this.loadRSeniorS();
            this.loadAbonos(this.id_proveedor);
            this.cleanAbono();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    sumFieldAbono(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsAbono.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSJ(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSSJ.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSPro(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsSeniorPro.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el rubro no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "RSenior/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rubro eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadRSenior();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El rubro no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
<style>
@media (max-width: 800px) {
  .phone-total {
    display: none;
  }
}
</style>
